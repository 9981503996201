var hellofaxJS = {

    sanitizeHTML: function(html) {

        var censor = function(s) {
            return s ? '...' : '';
        };

        var root = $(html);

        root.contents().each(function(i, el){
            if (el.nodeValue !== null) {
                // This is a piece of text
                el.nodeValue = censor(el.nodeValue);
            }
            else {
                // Go deeper
                el.outerHTML = hellofaxJS.sanitizeHTML(el.outerHTML);
            }
        });

        return $('<div></div>').append(root).html();
    },

    SITE_CODE_HELLOFAX: 'F',
    SITE_CODE_HELLOSIGN: 'S',
    BRAND_NAMES: {
        'F': 'HelloFax',
        'S': 'HelloSign'
    },

    isThirdPartyCookiesDisabled: function(){
        var token = (new Date()).getTime();
        Cookie.set('HS-TEST-COOKIE', token, 30*1000); // 30 secs TTL
        return (Cookie.get('HS-TEST-COOKIE') != token);
    },

    getSiteCode: function() {
        return hellofaxJS.getUserData().site_code;
    },

    getBrandName: function(site_code) {
        return hellofaxJS.BRAND_NAMES[site_code || hellofaxJS.getSiteCode()];
    },

    isHelloFax: function() {
        return hellofaxJS.getSiteCode() === hellofaxJS.SITE_CODE_HELLOFAX;
    },

    isHelloSign: function() {
        return hellofaxJS.getSiteCode() === hellofaxJS.SITE_CODE_HELLOSIGN;
    },

    isMobile: function() {
        //http://www.abeautifulsite.net/blog/2011/11/detecting-mobile-devices-with-javascript/
        var isMobile = {
            Android: function() { return navigator.userAgent.match(/Android/i); },
            BlackBerry: function() { return navigator.userAgent.match(/BlackBerry/i); },
            iOS: function() { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
            Opera: function() { return navigator.userAgent.match(/Opera Mini/i); },
            Windows: function() { return navigator.userAgent.match(/IEMobile/i); },
            any: function() { return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()); }
        };

        return isMobile.any();
    },



    lastAjaxTimestamp : 0,

    // Useful to get out of FB frames in a smooth way
    goToUrl: function(url) {
        $.fancybox.close();
        document.location = url;
    },

    getUrl: function(path, params) {
        var userData = hellofaxJS.getUserData();
        var baseUrl = userData && userData.base_url ? userData.base_url : '';
        var url = baseUrl + '/' + path;

        if (params) {
            var q = [];
            for (var k in params) {
                q.push(k + "=" + encodeURIComponent(params[k]));
            }
            if (q) {
                url += '?' + q.join('&');
            }
        }

        return url;
    },

    topWindowInDomain: function() {
        var currentWindow = null;
        try {
            currentWindow = window;
            while (currentWindow != window.parent) {
                if (window.parent.document) {
                    currentWindow = window.parent;
                }
                else {
                    // WebKit will end up here
                    break;
                }
            }
        }
        catch (e) {
            // most likely a "Permission denied" error due to being loaded in the Box.net iframe
        }

        return currentWindow;
    },

    wasUserAborted: function(xhr) {
        return !xhr.getAllResponseHeaders();
    },

    resizeFancyBoxPopup: function(dimensions) {
        var h = dimensions['height'];
        var w = dimensions['width'];
        $('#fancybox-wrap').css({
            height: h ? h + 20 : undefined,
            width: w
        });
        $('#fancybox-inner').css({
            height: h,
            width: w ? w - 10 : undefined
        });
    },

    /**
     * Let's try to use this as our main fancybox wrapper.
     *
     * You can pass any Fancybox options you want in the options param; they
     * will override the defaults you see below.
     *
     * @param options Fancybox options object
     * @param content URL (string) or HTMLElement node
     * @param className
     */
    openFancyBox: function(options, content, className) {
        className = className || 'settings-modal';

        // Set aside callbacks provided via options param
        var onComplete = options['onComplete'];
        var onClosed = options['onClosed'];
        delete options['onComplete'];
        delete options['onClosed'];

        var settings = $.extend({
            'content': '',
            'autoDimensions': true,
            'height': 400,
            'width': 370,
            'enableEscapeButton': false,
            'hideOnOverlayClick': false,
            'showCloseButton': true,
            'margin': 0,
            'padding': 0,
            'scrolling': 'no',
            'transitionIn': 'fade',
            'transitionOut': 'fade',
            'makeTrigger': true, // If true, we will be making a trigger rather than showing a fancybox directly
            'onStart': function() {
                $('#fancybox-overlay').addClass('m-fancybox-modal-overlay ' + className + ' is-hidden');
                $('#fancybox-wrap').addClass('m-fancybox-modal-wrap ' + className + ' is-hidden');
                $('#fancybox-inner').addClass('m-fancybox-modal-inner ' + className);
                //$('#fancybox-outer').addClass('m-fancybox-modal-outer ' + className);
            },
            'onComplete': function() {
                $.fancybox.center();
                $('#fancybox-overlay, #fancybox-wrap').removeClass('is-hidden');
                if (typeof onComplete === "function") {
                    onComplete();
                }
            },
            'onClosed': function() {
                $('#fancybox-overlay, #fancybox-wrap, #fancybox-inner').attr('class', '');
                if (typeof onClosed === "function") {
                    onClosed();
                }
            }
        }, options);

        if (settings.autoDimensions === true) {
            settings['height'] = undefined;
            settings['width'] = undefined;
        }

        if ((settings['makeTrigger'] == true) && (typeof content !== "string")) {
            content.fancybox(settings);
        } else {
            if (typeof content === "string") {
                settings['href'] = content;
                settings['type'] = 'iframe';

            } else {
                settings['content'] = content.get(0);
            }
            $.fancybox(settings);
        }
    },

    /**
     * Wrapper for openFancyBox
     *
     * @param url
     * @param options
     */
    openFancyBoxPopup: function(url, options) {
        hellofaxJS.openFancyBox(
            $.extend({
                'hideOnOverlayClick'    : true,
                'height'                : 370,
                'autoDimensions'        : false,
                'scrolling'             : 'yes',
                'href'                  : url,
                'margin'                : 0,
                'padding'               : 0
            }, options),
            url
        );
    },

    /**
     * Wrapper for openFancyBoxPopup; Let's try to consolidate when possible
     *
     * @param url
     * @param className
     * @param onComplete
     * @param onClosed
     * @param canScroll
     */
    openFancyBoxIFrame: function(url, className, onComplete, onClosed, canScroll) {
        className = className || 'settings-modal';
        hellofaxJS.openFancyBox(
            {
                'type':             'iframe',
                'showCloseButton':  false,
                'scrolling':        (canScroll || 'no'),
                'href':             url,
                'autoDimensions':   true,
            },
            url,
            className
        );
    },

    showGmailQuotaAlertModal: function(onComplete, onClosed) {
        $.fancybox(
            $('#quota_alert_gmail_num_documents_per_month'),
            {
                'transitionIn':     'none',
                'transitionOut':    'none',
                'showCloseButton':  false,
                'scrolling':        'no',
                'padding':          0,
                'margin':           0,
                'autoDimensions':   true,
                onStart: function(){
                    $('#fancybox-overlay').addClass('m-fancybox-modal-overlay is-hidden standard-modal');
                    $('#fancybox-wrap').addClass('m-fancybox-modal-wrap is-hidden standard-modal');
                    $('#fancybox-inner').addClass('m-fancybox-modal-inner standard-modal');
                },
                onComplete: function(){
                    $.fancybox.center();
                    $('#fancybox-overlay, #fancybox-wrap').removeClass('is-hidden');
                    if (typeof onComplete !== "undefined") {
                        onComplete();
                    }
                },
                onClosed: function(){
                    $('#fancybox-overlay, #fancybox-wrap, #fancybox-inner').attr('class', '');
                    if (typeof onClosed !== "undefined") {
                        onClosed();
                    }
                }
            }
        );
    },

    /**
     * Another Fancybox wrapper
     *
     * @param el
     * @param className
     */
    makeInlineFancyBoxPopup: function(el, className) {
        hellofaxJS.openFancyBox(
            {
                'showCloseButton':  false
            },
            el,
            className
        );
    },

    setUserData: function (user_data) {
        var defaults = {
            is_logged_in: false,
            has_remember_me: false,
            email_address: null
        };

        user_data = $.extend(defaults, user_data);
        //NOTE: Need to use top-most in domain window's jQuery object because that's where the caching is done
        (hellofaxJS.topWindowInDomain().$ || window.$).data(hellofaxJS.topWindowInDomain(), 'HFUser', user_data);
    },

    getUserData: function(key) {
        var data = (hellofaxJS.topWindowInDomain().$ || window.$).data(hellofaxJS.topWindowInDomain(), 'HFUser');
        return key !== undefined ? data[key] : data;
    },

    setLastAjaxTimestamp: function(ts) {
        this.lastAjaxTimestamp = ts;
    },

    allNotifications: [],
    removeProcessedNotification: function(notificationData) {
        var encodedNotificationData = Base64.encode(JSON.stringify(notificationData));
        this.allNotifications.splice(this.allNotifications.indexOf(encodedNotificationData), 1);
        document.getElementById('notification_content').innerHTML = JSON.stringify(this.allNotifications);
    },

    //checks for notifications to display onpageload in an HTML element, displays them and removes them
    processQueuedNotifications: function() {
        var notificationElement = document.getElementById('notification_content');
        try {
            this.allNotifications = (notificationElement) ? JSON.parse(notificationElement.innerHTML) : [];
            if (this.allNotifications.length) {
                //decode notification
                for (var i in this.allNotifications) {
                    var notificationData = this.allNotifications[i];
                    if (notificationData && notificationData !== "") {

                        notificationData = JSON.parse(Base64.decode(notificationData));

                        if (notificationData.class_name === "fancybox") {
                            this.removeProcessedNotification(notificationData); // only remove if it's fancybox notification
                            hellofaxJS.createModalNotification(notificationData);
                        } else { // everything else should be handled on the react side
                            hellofaxJS.createNotification(notificationData);
                        }
                    }
                }
            }
        } catch (e) {
            return false;
        }

    },

    //front end replacement for setPopupConfirmationMessage.  Displays a notification dropping down from the top
    createNotification: function(options) {
        console.log("Creating notification", options);
        if (window && window.HelloSign && window.HelloSign.displayNotification) {
            // Use shiny new React notification
            window.HelloSign.displayNotification(options);
        } else {
            // Use old skool notifications
            // Merge defaults and options
            var settings = $.extend({
                class_name: 'success', //info|error
                box_id: 'notification_' + Math.round(Math.random() * 99999),
                icon: '',
                header: '',
                text: '',
                display_time: 5500 //0 for persistent
            }, options);

            //get notification template
            var notification_html = $("#notification_template").html();

            if (notification_html) {
                //replace template variables
                for (var i in settings) {
                    notification_html = notification_html.replace(new RegExp("{{" + i + "}}", 'g'), settings[i]);
                }

                //convert to html
                var notification = $(notification_html);

                //remove icon from template if not provided
                if (settings.icon === '') {
                    notification.find('img.icon').remove();
                }

                //add click handler to close function
                notification.find('.close').click(function(e) {
                    e.preventDefault();
                    hellofaxJS.removeNotification($(this).parents('.notification'));
                    return false;
                });

                //add to body and animate in
                notification.appendTo("body");
                notification.animate({
                    'top': '0px'
                }, 800);

                //remove after specified time
                if (settings.display_time) {
                    setTimeout(hellofaxJS.removeNotification, settings.display_time);
                }
            }
        }
    },

    /**
     * Yet another fancybox wrapper
     *
     * @deprecated
     * @param options
     */
    createModalNotification: function(options) {
        if (options.text) {
            //make sure html is unescaped
            options.content = options.text;
            options.content = options.content.replace(/&quot;/g, "\"");
            options.content = options.content.replace(/&gt;/g, ">");
            options.content = options.content.replace(/&lt;/g, "<");
            delete options.text;
        }
        var settings = $.extend({
            content: '',
            autoDimensions: true,
            display_time: 0, //0 for persistent, otherwise time in milliseconds
            height: 400,
            width: 400,
            enableEscapeButton: true,
            hideOnOverlayClick: false,
            showCloseButton: true,
            onComplete: null,
            onClosed: null
        }, options);
        //get notification template
        var notification_html = $("#modal_notification_template").html();

        //replace template variables
        for (var i in settings) {
            notification_html = notification_html.replace(new RegExp("{{" + i + "}}", 'g'), settings[i]);
        }

        //convert to html
        var notification = $(notification_html);

        var fancy_options = {
            content: notification.html(),
            autoDimensions: settings.autoDimensions,
            height: settings.height,
            width: settings.width,
            enableEscapeButton: settings.enableEscapeButton,
            onComplete: settings.onComplete,
            onClosed: settings.onClosed,
            showCloseButton: settings.showCloseButton,
            hideOnOverlayClick: settings.hideOnOverlayClick
        };

        //if there's a url provided don't pass content
        if (settings.href) {
            fancy_options.href = settings.href;
            fancy_options.type = 'iframe';
            delete fancy_options.content;
        }

        var wrap = $('#fancybox-wrap');
        var overlay = $('#fancybox-overlay');
        if (overlay.is(':visible') && (!wrap.is(':visible') || wrap.css('opacity') < 1))  {

            // Wait for the previous modal to have faded out
            var hasFadedOut = function() {
                return ((wrap.css('opacity') === 0 || !wrap.is(':visible')) && (overlay.css('opacity') === 0 || !overlay.is(':visible')));
            };
            var check = function() {
                if (!hasFadedOut()) {
                    // Check again in a little while
                    setTimeout(check, 50);
                }
                else {
                    // show the modal
                    $.fancybox(fancy_options);
                }
            };
            check();

        }
        else {

            //show modal without waiting
            $.fancybox(fancy_options);

        }

        //remove after specified time
        if (settings.display_time) {
            setTimeout(hellofaxJS.removeModalNotification, settings.display_time);
        }

        if (settings.hideOverflow) {
            setTimeout(function() {
                $("#fancybox-inner").css('overflow', 'hidden');
            }, 500);
        }
    },

    removeModalNotification: function() {
        $.fancybox.close();
    },

    removeNotification: function(elem) {
        if (!elem) {
            elem = $(".notification");
        }

        var animate_options = {};

        if(!$(elem).hasClass('notification-error')) {
            animate_options['top'] = '-100px';
        } else {
            animate_options = {
                height: '0px',
                opacity: '.1'
            };
        }

        $(elem).animate(animate_options, 800, function() {
            $(this).parent().remove();

            //show next notification in line if necessary
            hellofaxJS.processQueuedNotifications();
        });
    },

    // hellofaxJS.enableToolTips('.details', {top: -20, left: 25}, true|false);
    enableToolTips: function(selector, offset, triggerOnClick) {
        selector = selector || ".hf-tooltip";
        offset = offset || { top: -20, left: 20};
        var infoBubble = $("#infoBubble");

        if (infoBubble.length === 0) {
            infoBubble = $(
                "<div id='infoBubble'>" +
                    "<span class='arrow'></span>" +
                    "<span class='content'></span>" +
                "</div>"
            ).appendTo('body');
        }

        var showBubble = function(e){
            var el = $(this);
            var descr = $(this).attr('data');
            var pos = {
                top: el.offset().top,
                left: el.offset().left
            };
            var content = $('.content', infoBubble);

            content.html(descr);

            // Render out of screen
            infoBubble
                .css('top', '-10000px')
                .show();

            // Adjust position
            infoBubble
                .css({
                    'top': (pos.top + offset.top) + 'px',
                    'left': (pos.left + el.width() + offset.left) + 'px'
                });

            hellofaxJS.tooltipOn = true;

            e.preventDefault();
            e.stopPropagation();
        };

        var hideBubbleOnExit = function(e) {
            infoBubble
                .removeClass('large')
                .hide();

            hellofaxJS.tooltipOn = false;
        };

        var hideBubbleOnClickOut = function(e){
            if (hellofaxJS.tooltipOn) {
                var t = $(e.target);
                if (!t.hasClass(selector.replace('.', '')) && t.parents([selector, '#infoBubble'].join(', ')).length === 0) {
                    hideBubbleOnExit();
                }
            }
        };

        if (triggerOnClick === true) {
            $(selector).click(showBubble);
            $(document).click(hideBubbleOnClickOut);
        }
        else {
            $(selector)
                .mouseenter(showBubble)
                .mouseleave(hideBubbleOnExit);
        }
    },

    isValidPhoneInternational: function(phone_number, force){
        if (force === true || hellofaxJS.isHelloFax()) {
            var options = hellofaxJS.getFormattedPhoneOptions(phone_number);
            return (options.length > 0 ? true : false);
        }
        return true;
    },

    isValidEmailAddress: function(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    isIE7: function() {
        return (document.all && !window.opera && window.XMLHttpRequest && navigator.userAgent.toString().toLowerCase().indexOf('Trident/4.0') == -1) ? true : false;
    },

    sanitizeInputEmailAddress: function(emailInput, callback) {
        var input = $(emailInput);
        // Set timeout so it executes on the new value instead of the pre-paste value
        setTimeout(function() {
            if (input && input.length > 0) {
                var sanitized = $.trim(input.val().replace(/["]+/g, ''));
                input.val(sanitized);
                if (callback) {
                    callback(sanitized);
                }
            }
        }, 1);
    },

    trackEvent: function(action, category, label, value) {
        hellofaxJS.trackGoogleAnalyticsEvent(action, category, label, value);
    },

    trackGoogleAnalyticsEvent: function(action, category, label, value) {
        if(!category) {
            category = 'uncategorized';
        }
        if(!value) {
            value = 0;
        }
        if(!label) {
            label = '';
        }
        try {
            if (typeof dataLayer === 'object') {
                dataLayer.push({
                    'event': 'GAEvent',
                    'eventAction': action,
                    'eventCategory': category,
                    'eventLabel': label,
                    'eventValue': value
                });
            }
            else if (typeof ga === 'function') {
                ga('send', 'event', category, action, label, value);
            }
        }
        catch (e) {
            l('ERROR: Could not report GA event (' + e.message + ')');
        }
    },

    trackGAPageViewEvent: function(type, visitorId, subscription, plan, product) {
        if(!visitorId) {
          visitorId = '';
        }

        if(!subscription) {
          subscription = '';
        }

        if(!plan) {
          plan = '';
        }

        if(!product) {
          product = '';
        }

        try {
            dataLayer.push({
              'pageName': window.location.pathname,
              'pageType': type,
              'environment': process.env.NODE_ENV,
              'visitorID': visitorId,
              'subscriptionPlan': subscription,
              'productPlan': plan,
              'productType': product,
              'accountType': 'con'
            });
        }
        catch (e) {
            l('ERROR: Could not report GA event (' + e.message + ')');
        }
    },

    /* ----- I18N / PHONE NUMBER  ------------------------- */

    _initI18nPhoneNumber: function() {
        if (!hellofaxJS.phoneUtil) {

            // Initialize the phone helpers
            hellofaxJS.phoneUtil = i18n.phonenumbers.PhoneNumberUtil.getInstance();

            // Fix to be able to recognize all numbers from Guadeloupe (Google's version is flawed)
            i18n.phonenumbers.metadata.countryToMetadata["GP"] = [, [, , "[56]\\d{8}", "\\d{9}"], [, , "590\\d{6}", "\\d{9}", , , "590201234"], [, , "690(?:0[0-7]|[1-9]\\d)\\d{4}", "\\d{9}", , , "690301234"], [, , "NA", "NA"], [, , "NA", "NA"], [, , "NA", "NA"], [, , "NA", "NA"], [, , "NA", "NA"], "GP", 590, "00", "0", , , "0", , , , [[, "([56]90)(\\d{2})(\\d{4})", "$1 $2-$3", , "0$1", "", 0]], , [, , "NA", "NA"], 1, , [, , "NA", "NA"], [, , "NA", "NA"], , , [, , "NA", "NA"]];

        }
    },

    getFormattedPhoneOptions: function(number) {
        hellofaxJS._initI18nPhoneNumber();
        return hellofaxJS.phoneUtil.getFormattedPhoneOptions(number, hellofaxJS.phoneUtil);
    },

    /**
     * @number - internationally formatted fax number (digit wise, eg: these are all the same: '1555-555-5555' '15555555555' '1 (555) 555-5555'
     */
    formatInternationalFaxNumber: function(number) {

        hellofaxJS._initI18nPhoneNumber();

        var options = hellofaxJS.getFormattedPhoneOptions(number);

        return hellofaxJS.phoneUtil.formatInternationalFaxNumber(number, options);
    },

    updateDestinationOptions: function(evt, obj){
        if (hellofaxJS.isHelloFax()) {

            // allow number to be formatted if the user hits enter (but don't submit the form)
            if (evt && evt.type === 'keypress') {
                if (evt.which !== $.ui.keyCode.ENTER) {
                    return;
                }
                evt.preventDefault();
            }

            var el = $(this);
            if (typeof obj !== 'undefined') {
                el = $(obj);
            }
            // If not changed, skip
            if (el.attr('changed') == el.val()) {
                return true;
            }

            var destination = el.parent().find("span.destination").html('');
            var options = hellofaxJS.getFormattedPhoneOptions(el.val());

            hellofaxJS.phoneUtil.updateDestinationOptions(el, destination, options);
        }
    },

    closeAllCountryDropdowns: function() {
        $(".showing_destination_picker").removeClass('showing_destination_picker');
        $(document).unbind('click', hellofaxJS.closeAllCountryDropdowns);
    },

    hasDST: function(tz_abbr) {
        var y = new Date().getFullYear();
        var d_winter = new Date(y + '/01/01' + (tz_abbr ? ' ' + tz_abbr : ''));
        var d_summer = new Date(y + '/06/01' + (tz_abbr ? ' ' + tz_abbr : ''));
        var offset_delta = Math.abs(d_winter.getTimezoneOffset() - d_summer.getTimezoneOffset());
        return offset_delta == 60;
    },

    getTZOffset: function(tz_abbr) {
        var y = new Date().getFullYear();
        var d1 = new Date(y + '/01/01' + (tz_abbr ? ' ' + tz_abbr : ''));
        var d2 = new Date(y + '/01/01 GMT');
        return d1.getTime() - d2.getTime();
    },

    getUserTZOffset: function() {
        var userData = hellofaxJS.getUserData();
        var tzOffset = userData['timezone_offset'];
        if (tzOffset === null) {
            return -hellofaxJS.getTZOffset() / (3600 * 1000);
        }
        return tzOffset;
    },

    generateMailToLink: function(to_name, to_domain, subject) {
        document.write("<a href=\"mailto");
        document.write(":" + to_name + "@");
        document.write(to_domain + "?subject=" + subject + "\">" + to_name + "@" + to_domain + "<\/a>");
    },

    trackUrlParams: function() {
        var params = {};
        var ps = window.location.search.split(/\?|&/);

        for (var i = 0; i < ps.length; i++) {
            var p = ps[i].split(/=/);

            if (params[p[0]]) {
                params[p[0]].push(p[1]);
            }
            else {
                params[p[0]] = [p[1]];
            }
        }
    },

    prettyPrintJSON: function(el) {
        (el || $("pre.json-prettify")).each(function() {
            var json = $(this).html();

            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                        var cls = 'number';
                        if (/^("|')/.test(match)) {
                            if (/:$/.test(match)) {
                                cls = 'key';
                            } else {
                                cls = 'string';
                            }
                        } else if (/true|false/.test(match)) {
                            cls = 'boolean';
                        } else if (/null/.test(match)) {
                            cls = 'null';
                        }
                        return '<span class="' + cls + '">' + match + '</span>';
                    });

            $(this).html(json);

        });
    },

    addGmailInstallBar: function() {
        if (hellofaxJS.isHelloSign()) {

            hellofaxJS.trackEvent('gmail_install_displayed');

            var topbar = $("#topbar").addClass('gmail-not-installed');
            var installbar = $("<div id='gmail_install_bar'><div/>");

            installbar  .addClass('cws-install-bar')
                        .addClass('gmail-extension-require-not-installed')
                        .html('<p>Sign documents in Gmail</p><a href="/gmail" class="install-btn">Install</a><a class="cws-close">&times;</a>')
                        .find('.cws-close')
                            .click(function(e) {
                                hellofaxJS.removeGmailInstallBar();
                                hellofaxJS.trackEvent('gmail_install_close_clicked');
                                return false;
                            })
                            .end()
                            .find('.install-btn')
                            .click(function(e) {
                                hellofaxJS.trackEvent('gmail_install_clicked');
                            })
                        .end()
                        .insertBefore(topbar);
        }
    },

    removeGmailInstallBar: function() {
        $("#gmail_install_bar").animate({
            marginTop: '-36px'
        }, 500, function() {
            $(this).remove();
        });
    },

    attemptChromeAppInstall: function() {
        if (!hellofaxJS.chromeAppInstallBarText) {
            hellofaxJS.chromeAppInstallBarText = hellofaxJS.isHelloFax() ? "HelloFax now has a Google Chrome App. Send faxes & sign documents from Google Drive." : "HelloSign now has a Google Chrome App.  Sign documents from Google Drive";
        }

        if (window.chrome && chrome.app && !chrome.app.isInstalled) {
            hellofaxJS.trackEvent('cws_install_displayed');

            var installbar = $("<div id='cws_install_bar'><div/>");

            installbar  .addClass('cws-install-bar')
                        .html('<p>' + hellofaxJS.chromeAppInstallBarText + '</p><a class="install-btn">Install</a><a class="cws-close">&times;</a>')
                        .find('.cws-close')
                            .click(function(e) {
                                hellofaxJS.removeChromeAppInstallBar();
                                hellofaxJS.trackEvent('cws_install_close_clicked');
                                return false;
                            })
                        .end()
                        .find('.install-btn')
                            .attr('href', $("#chrome-webstore-item").attr('href'))
                            .attr('target', '_blank')
                            .attr('rel', 'noopener noreferrer')
                            .click(function(e) {
                                hellofaxJS.trackEvent('cws_install_clicked');
                                hellofaxJS.removeChromeAppInstallBar();
                            })
                        .end()
                        .insertBefore('#topbar');
            return true;
        }

        return false;
    },

    removeChromeAppInstallBar: function() {
        $("#cws_install_bar").animate({
            marginTop: '-36px'
        }, 500, function() {
            $(this).remove();
        });
    },

    /**
     * Wrap text in a string to a maximum line length (imitates PHP's wordwrap)
     *
     * @param {string} str Input string
     * @param {int} width Maximum number of characters per line
     * @param {string} brk Line break to use (defaults to newline character)
     * @param {bool} cut If false, only wrap on word boundaries
     * @returns {string} The word-wrapped string
     */
    wordwrap: function( str, width, brk, cut ) {
        brk = brk || '\n';
        width = width || 75;
        cut = cut || false;

        if (!str) { return str; }

        var regex = RegExp('.{1,' +width+ '}(\\s|$)' + (cut ? '|.{' +width+ '}|.+$' : '|\\S+?(\\s|$)'), 'g');

        var inputLines = str.split(brk);
        var outputLines = inputLines.map(function(line) {
            var match = line.match(regex);
            return (match) ? match.join(brk) : match;
        });
        return outputLines.join(brk);
    },

    parseQueryString: function(qs, decode) {
        if (qs) {
            var parts = qs.replace(/^\?/, '').split('&');
            var p, params = {};
            for (var i = 0; i < parts.length; i++) {
                p = parts[i].split('=');
                if (p && p.length === 2) {
                    params[p[0]] = decode !== false ? decodeURIComponent(p[1]) : p[1];
                }
            }
            return params;
        }
    },

    removeParameterFromUrl: function(url, paramName) {
        if (url) {
            var p = url.indexOf('?');
            if (p >= 0) {
                var base = url.substring(0, p);
                var qs = url.substring(p+1);
                var params = this.parseQueryString(qs, false);
                if (params) {
                    var pairs = [];
                    for (var k in params) {
                        if (k !== paramName) {
                            pairs.push(k + '=' + params[k]);
                        }
                    }
                    url = base + '?' + pairs.join('&');
                }
            }
        }
        return url;
    },

    getSessionName: function() {
        var sessionInfo = this.getSessionInfoFromUrl();
        if (sessionInfo) {
            return sessionInfo.name;
        }
    },

    getSessionInfoFromUrl: function() {
        if (window != window.top) {                                 // Only do this if we're in an iFrame
            if (!window.sessionInfo && document.location.search) {  // Only bother if there's a query string

                var params = this.parseQueryString(document.location.search);

                if (params) {
                    var sessionName = params['session_name'];
                    if (sessionName) {
                        var sessionInfo = params[sessionName];
                        if (sessionInfo) {
                            var s = sessionInfo.split(':');
                            window.sessionInfo = {
                                'name': sessionName,
                                'id': s[0],
                                'signature': s[1]
                            };
                        }
                    }
                }
            }
            return window.sessionInfo;
        }
    },

    attachSessionInfoToUrl: function(url) {
        if (url) {
            var sessionInfo = this.getSessionInfoFromUrl();
            if (sessionInfo) {
                url += (url.indexOf('?') >= 0 ? '&' : '?') + sessionInfo.name + '=' + sessionInfo.id + ':' + sessionInfo.signature;
            }
        }
        return url;
    }
};

var Cookie = {

    get: function(key) {
        var parts, pairs = document.cookie.split(";");
        for (var k in pairs) {
            parts = $.trim(pairs[k]).split("=");
            if (parts.shift() == key) {
                return parts.join("=");
            }
        }
    },

    set: function(key, value, ttl_ms, path) {
        var c = key + "=" + value;
        if (ttl_ms !== undefined) {
            var d = new Date();
            d.setTime(d.getTime() + ttl_ms);
            c += "; expires=" + d.toUTCString();
        }
        if (path === undefined) {
            path = document.location.pathname;
        }
        if (path) { // This way we can skip setting path by using path=null
            c += "; path=" + path;
        }
        c += "; domain=" + document.domain + ";";
        document.cookie = c;
    },

    clear: function(key, path) {
        if (path === undefined) {
            path = '/';
        }
        this.set(key, '', 0, path)
    }
};

var Base64 = {

    // private property
    _keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    // public method for encoding
    encode : function (input) {

        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
            this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
            this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },

    // public method for decoding
    decode : function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    // private method for UTF-8 encoding
    _utf8_encode : function (string) {

        string = string.replace(/\r\n/g,"\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    // private method for UTF-8 decoding
    _utf8_decode : function (utftext) {

        var string = "";
        var i = 0;
        var c = 0;
        var c1 = 0;
        var c2 = 0;
        var c3;

        while ( i < utftext.length ) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i+1);
                c3 = utftext.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }
};

var SessionTimeoutMonitor = {

    WARNING_DELAY: 55 * 60, // 55 * 60 = 55 minutes... this should be less than the factories.yml user timeout
    TIMEOUT_DELAY: 56 * 60,
    CHECK_PERIOD: 30 * 1000,

    timer: null,
    sameDomainTopWindow: null,
    inIntraDomainIFrame: null,

    getNow: function() {
        var now = Math.round(new Date().getTime() / 1000);
        return now;
    },

    getTimeDelta: function() {
        var now = this.getNow();
        return now - (hellofaxJS.lastAjaxTimestamp || 0);
    },

    keepSessionAlive: function(callback) {
        l('Keeping session alive');
        $.ajax({
            url: hellofaxJS.getUrl('account/keepSessionAlive'),
            type: 'GET',
            success: callback
        });
    },

    logUserOut: function() {

        l('Logging out');

        if ($('#fancybox-frame').length) {
            var popUpFrame = window.frames[$('#fancybox-frame').attr("name")];
            if (popUpFrame) {
                popUpFrame.window.onbeforeunload = function() {};
                var editor = $(popUpFrame).data('editor');
                if (editor) {
                    editor.autoSave();
                }
            }
        }

        $(window).unbind('beforeunload');

        var tsm_group_guid = $('body').data('tsm_group_guid');
        var redirUrl = tsm_group_guid ? '/send/resendDocs?tsm_group_guid=' + tsm_group_guid : this.sameDomainTopWindow.location;
        var logOutUrl = hellofaxJS.getUrl('account/logOut', {
            'is_timeout': 1,
            'n': this.getNow(),
            'l': hellofaxJS.lastAjaxTimestamp,
            'on_login_redirect_url': redirUrl
        });
        if (this.inIntraDomainIFrame) {
            this.sameDomainTopWindow.$(window).unbind('beforeunload');
            this.sameDomainTopWindow.$.fancybox.close();
            this.sameDomainTopWindow.location = logOutUrl;
        }
        else {
            window.location = logOutUrl;
        }
    },

    showLogOutWarning: function() {

        var self = this;
        var userData = hellofaxJS.getUserData();
        if (userData['no_timeout'] === true) {
            return;
        }

        l('Showing timeout warning');

        this.stop();
        alert("You're about to be logged out due to inactivity. Click on 'OK' within the next minute to stay logged in.");

        if (this.getTimeDelta() >= SessionTimeoutMonitor.TIMEOUT_DELAY) {

            alert("You have been logged out due to inactivity.");

            // Proceed to log the user out
            this.logUserOut();

        }
        else {

            // Attempt to revive the session
            this.keepSessionAlive(function(){
                hellofaxJS.lastAjaxTimestamp = self.getNow();
                self.start();
            });

        }
    },

    check: function() {

        var now = Math.round(new Date().getTime() / 1000);
        var secondsSinceLastAjax = now - hellofaxJS.lastAjaxTimestamp;
        var w = this.inIntraDomainIFrame ? this.sameDomainTopWindow : window;
        var isAlreadyRunning = false;

        if (secondsSinceLastAjax >= SessionTimeoutMonitor.WARNING_DELAY) {

            if (w.$('body').data('is_redirecting') !== true) {
                w.$('body').data('is_redirecting', true);
            }
            else {
                isAlreadyRunning = true;
            }

            if (!isAlreadyRunning && !(location.hostname == "dev-hellosign.com" || location.hostname.match(/(www|app)\.dev-hellosign\.com/))) {
                this.showLogOutWarning();
            }

        }

        w.$('body').data('is_redirecting', false);
    },

    start: function() {
        var self = this;
        if (!this.timer) {
            l('Starting SessionTimeoutMonitor');
            this.sameDomainTopWindow = hellofaxJS.topWindowInDomain();
            this.inIntraDomainIFrame = (window.location != this.sameDomainTopWindow.location);
            this.timer = setInterval(function(){ self.check(); }, SessionTimeoutMonitor.CHECK_PERIOD);
        }
    },

    stop: function() {
        if (this.timer) {
            l('Stopping SessionTimeoutMonitor');
            clearInterval(this.timer);
            this.timer = null;
        }
    }
};

$(document).ready(function() {

    var sameDomainTopWindow = hellofaxJS.topWindowInDomain();
    var inIntraDomainIFrame = (window.location != sameDomainTopWindow.location);
    var now = Math.round(new Date().getTime() / 1000);
    var userData = hellofaxJS.getUserData();

    hellofaxJS.lastAjaxTimestamp = now;
    if (inIntraDomainIFrame && sameDomainTopWindow.hellofaxJS !== undefined) {
        sameDomainTopWindow.hellofaxJS.setLastAjaxTimestamp(now);
    }

    if (userData !== undefined && userData.is_logged_in && !userData.has_remember_me) {
        SessionTimeoutMonitor.start();
    }
});

// IE8/9 shim
if(!window.console) {
    window.console={};
    window.console.log = function(){};
    window.console.warn = function(){};
}

function l(str){
    if (window.console && console.log) {
        try {
            console.log(str);
        }
        catch(e) {}
    }
}

//IE8 don't need no stinking Date.now
Date.now = Date.now || function() { return +new Date; };

//So that we can get the "size" of objects (which take the place of associative arrays)
Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

//IE8 and below
Object.keys = Object.keys || (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{toString:null}.propertyIsEnumerable("toString"),
        DontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
        ],
        DontEnumsLength = DontEnums.length;

    return function (o) {
        if (typeof o != "object" && typeof o != "function" || o === null)
            throw new TypeError("Object.keys called on a non-object");

        var result = [];
        for (var name in o) {
            if (hasOwnProperty.call(o, name))
                result.push(name);
        }

        if (hasDontEnumBug) {
            for (var i = 0; i < DontEnumsLength; i++) {
                if (hasOwnProperty.call(o, DontEnums[i]))
                    result.push(DontEnums[i]);
            }
        }

        return result;
    };
})();

//IE8 and below
String.prototype.trim = String.prototype.trim || (function() {
    return $.trim(this);
});

var trigger = "live";
// jQuery feature detection
if (typeof $().live !== 'function') {
    trigger = "on";
}
$("#tsm_group_send input.recipient")[trigger]('blur', hellofaxJS.updateDestinationOptions);
$("#tsm_group_send input.recipient")[trigger]('keypress', hellofaxJS.updateDestinationOptions);

$("span.multiple_destinations")[trigger]('click', function(e) {
    $(this).toggleClass('showing_destination_picker');

    if ($(this).hasClass('showing_destination_picker')) {
        $(document).click(hellofaxJS.closeAllCountryDropdowns);
    }
});

$(".destination_option")[trigger]('click', function(e) {

    var country = $(this).attr('country');
    if (country) {
        $(this).parent().find(".destination_text").html(country.toLowerCase());
    }

    $(this).parent().find('.selected').removeClass('selected');

    var el = $(this).addClass('selected')
        .parents(".recipient_container")
        .find("input.recipient");

    var number = $(this).attr('number');
    if (number) {
        el  .val(number)
            .attr('changed', number);
    }

    destinationPicker.toggleMultiplierWarning();

    window.elem = this;
});

// Getter to safely retrieve localStorage and avoid throwing tons of exceptions
// in private browsing mode or when stricter security rules are in application
var getLocalStorage = function() {

    try {
        if (typeof localStorage !== "undefined") {
            return localStorage;
        }
    }
    catch (e) {
        if (e.name !== 'SecurityError') {
            // Only skip if it's a security error
            throw e;
        }
    }

    // Return stub
    return {
        setItem: function(){},
        getItem: function(){},
        removeItem: function(){}
    };
};


// Global exports to make it work with webpack
window.hellofaxJS = hellofaxJS;
window.Cookie = Cookie;
window.l = l;
window.Base64 = Base64;
window.SessionTimeoutMonitor = SessionTimeoutMonitor;
window.getLocalStorage = getLocalStorage;
