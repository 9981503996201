(function($) {
    $.extend($.ui.tabs.prototype, {
        _load25624: $.ui.tabs.prototype.load,
        itemOptions: [],
        load: function(index) {
            index = this._getIndex(index);

            var o = this.options,
                a = this.anchors.eq(index)[0];

            try {
            	if(o.itemOptions[index].cache === false) {
                    $.data(a, "cache.tabs", false);
            		$.data(a, "hellofax.isCached", false);
            	}
            }
            catch(e) { }

            return this._load25624(index);
        }
    });
})(jQuery);
